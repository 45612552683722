import React from "react";

export default function AuthComplete () {
  return (
    <>
      <p>Signed in successfully</p>
      <p>VoxSmart is connected to your Telegram account</p>
      <p>It is now safe to close this window.</p>
    </>
  );
};
