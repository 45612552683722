import React from 'react';

import {
  toast as originalToast,
  ToastContainer as OriginalToastContainer,
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './toast.scss';


export const toastOptions = {
  autoClose: 6000,
  position: originalToast.POSITION.TOP_RIGHT
};

export const dismissToast = originalToast.dismiss;

export const plainToast = message => originalToast(message);

export const warningToast = message => originalToast.warn(message);

export const infoToast = message => originalToast.info(message);

export const successToast = message => originalToast.success(message);

export const errorToast = message => originalToast.error(message, {autoClose: false});

const ToastContainer = () => <OriginalToastContainer {...toastOptions} />;

export default ToastContainer;
