import React from 'react';

import VsoxConnectView from './VsoxConnectView';
import ToastContainer from './Toasts';

import logo from './vs-logo-nav.svg';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="VoxSmart" />
      </header>
      <div className="main">
        <ToastContainer />
        <VsoxConnectView/>
      </div>
    </div>
  );
}

export default App;
