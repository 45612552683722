export interface ApiResult {
  isComplete: boolean,
};

export async function post(url: string, headers = {}) : Promise<Response> {
  return tcapFetch(url, headers, 'POST');
}

export async function put(url: string, headers = {}) : Promise<Response> {
  return tcapFetch(url, headers, 'PUT');
}

export async function get(url: string, headers = {}) : Promise<Response> {
  return tcapFetch(url, headers);
}

export async function tcapFetch(url: string, headers = {}, method='GET') : Promise<Response>  {
  const apiUrl = buildApiUrl(url);

  const fetchOptions = {
    method,
    headers: {
      'Accept': 'application/json, text/plain',
      ...headers,
    },
  };

  const fetchResult = await fetch(`${apiUrl}`, fetchOptions);

  if (!fetchResult.status) {
    const text = await fetchResult.text();
    throw new ApiError(`hostAPI fail ${url}: ${fetchResult.statusText}, ${text}`, fetchResult);
  }

  return fetchResult;
}

export enum RegistrationStartResult {
  started = 1,
  AlreadyCapturing,
}

export async function startRegistration(longOTC: string) : Promise<RegistrationStartResult> {
  if (longOTC === '12345678-1234-4321-89AB-123456789ABC') {
    return await runTestStart(longOTC);
  }

  const urlParts = [
    'registerOTC',
    encodeURI(longOTC),
  ];
  const apiResult = await post(urlParts.join('/'));
  if (apiResult.status === 201) {
    return RegistrationStartResult.started;
  }
  if (apiResult.status === 226) {
    return RegistrationStartResult.AlreadyCapturing;
  }
  else if (apiResult.status >= 400 && apiResult.status <= 499) {
    throw new Error('Error finding session. Please check you are using the link from the email we sent most recently.');
  }
  else if (apiResult.status >= 500) {
    throw new Error('Server encountered an unexpected Error. Please try again later or report to VoxSmart support.');
  }
  throw new Error(`Unexpected error calling registration start. ${apiResult.status}: ${apiResult.statusText}`);
}

export async function submitAuthCodes(longOTC: string, shortOTC: string) {
  let apiResult;
  // testing route
  if (longOTC === '12345678-1234-4321-89AB-123456789ABC') {
    apiResult = await runTestAuth(longOTC, shortOTC);
  }
  else {
    const urlParts = [
      'register',
      encodeURI(longOTC),
      'otc',
      encodeURI(shortOTC),
    ];
    apiResult = await put(urlParts.join('/'));
  }

  if (apiResult.status === 226 || apiResult.status === 200) {
    return;
  }
  if (apiResult.status === 400) {
    throw new Error('Auth code invalid. Please check the code sent by Telegram and try again.');
  }
  if (apiResult.status === 404) {
    throw new Error('Error finding session. Please check you are using the link from the email we sent most recently.');
  }
  if (apiResult.status >= 500) {
    throw new Error('Server encountered an unexpected Error. Please try again later or report to VoxSmart support.');
  }
}

async function runTestStart(longOTC: string){
  return new Promise<RegistrationStartResult>((resolve) => {
    setTimeout(() => {
      resolve(RegistrationStartResult.started);
    }, 1000);
  });
}

async function runTestAuth(longOTC: string, shortOTC: string) {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      if (shortOTC === '123456') {
        return resolve({status: 200});
      }
      if (shortOTC === '00404') {
        return resolve({status: 404});
      }
      if (shortOTC >= '00500') {
        return resolve({status: 500});
      }
      return resolve({status: 400});
    }, 3000);
  });
}

function buildApiUrl(endPoint: string) {
  const apiHost = getHost();

  return `${apiHost}/tcap/${endPoint}`;
}

function getHost() {
  return (window as any).VS_SETTINGS.apiHost;
}

class ApiError extends Error {
  readonly response: Response;

  constructor(text: string, response: Response) {
    super(text);
    this.response = response;
  }
}
