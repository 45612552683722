import {startRegistration, RegistrationStartResult} from '../serverApi';

export enum RegistrationStates {
  PageLoading, // haven't started talking to the server yet.
  Started, // Sent the LongOTC to server, awaiting success.
  AwaitingShortOTC, // server has started registration, waiting for user to enter shortOTC.
  SendingShortOTC, // Sent ShortOTC to server waiting response.
  Completed, // Server has completed Registration. We are now capturing the users messages.
  LongOtcFailed, // There was an error with the Long OTC.
  ShortOtcFailed, // There was an error with the Short OTC
}

export const getLongOtc = function () {
  const urlHash = window.location.hash;
  // matches on valid uuid-v4
  const guidMatch = urlHash
    .match(/^#?([0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})$/i);

  if (guidMatch && guidMatch.length > 1) {
    return guidMatch[1];
  }
};

export const getClientNumber = function (setClientNumber: (x: string) => void) {
  const urlHash = window.location.hash;
  const phoneMatch = urlHash.match(/^#([0-9]{4,15})$/);
  if (phoneMatch && phoneMatch.length > 1) {
    setClientNumber(phoneMatch[1]);
  }
};

export async function startRegistrationProcess(setLongOTC: (x: string) => void, setAuthState: (x: RegistrationStates) => void) {
  const longOtc = getLongOtc();
  if (longOtc) {
    setLongOTC(longOtc);
    setAuthState(RegistrationStates.Started);
    const result = await startRegistration(longOtc);
    if (result === RegistrationStartResult.AlreadyCapturing) {
      setAuthState(RegistrationStates.Completed);
    }
    setAuthState(RegistrationStates.AwaitingShortOTC);
  }
  else {
    throw new Error('Incorrect URL Token');
  }
}
