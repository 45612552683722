import React, {useEffect, useState} from 'react';
import {submitAuthCodes} from '../serverApi';

import {errorToast} from '../Toasts';
import AuthView from './AuthView';
import AuthComplete from './AuthComplete';
import LoadingSpinner from '../LoadingSpinner';
import {RegistrationStates, startRegistrationProcess} from "./AuthEffects";

import './tcap-connect.scss';


type AuthContextProps = {
  shortOTC: string,
  onTimeoutHandler: any,
};

export const AuthContext = React.createContext<Partial<AuthContextProps>>({});


export default function VsoxConnectView() {
  const [longOTC, setLongOTC] = useState('');
  const [shortOTC, setShortOTC] = useState('');
  const [authState, setAuthState] = useState(RegistrationStates.PageLoading);
  const [errorMessage, setErrorMessage] = useState('');

  function setError(errorMessage: string, errorState: RegistrationStates) {
    setErrorMessage(errorMessage);
    setAuthState(errorState);
  }

  useEffect(() => {
    async function callAsync() {
      try {
        if (authState === RegistrationStates.PageLoading) {
          await startRegistrationProcess(setLongOTC, setAuthState);
        }
      }
      catch (error) {
        setError(error.message, RegistrationStates.LongOtcFailed);
      }
    }

    callAsync();
  } , [authState, longOTC, shortOTC, errorMessage]);


  return (
    <AuthContext.Provider value={{ shortOTC, onTimeoutHandler }}>
      <div className="tcap-connect">
        <h1>VoxSmart User Authentication</h1>
        {authState === RegistrationStates.Started &&
          <div>
            <p>Starting login process. Please wait</p>
            <p><LoadingSpinner /></p>
          </div>
        }
        {(authState === RegistrationStates.AwaitingShortOTC  || authState === RegistrationStates.ShortOtcFailed) &&
          <AuthView {...{verifyTokens, shortOTC, setShortOTC}}/>
        }
        {authState === RegistrationStates.SendingShortOTC &&
          <div>
            <p>Connecting to Telegram</p>
            <p><LoadingSpinner /></p>
          </div>
        }
        {authState === RegistrationStates.Completed &&
          <AuthComplete/>
        }
        {(authState === RegistrationStates.LongOtcFailed || authState === RegistrationStates.ShortOtcFailed ) &&
          <p className="error">Error: {errorMessage}</p>
        }
      </div>
    </AuthContext.Provider>
  );

  async function verifyTokens() {
    console.info('verifyTokens', {longOTC, shortOTC});
    setAuthState(RegistrationStates.SendingShortOTC);

    try {
      if (longOTC && shortOTC) {
        await submitAuthCodes(longOTC, shortOTC);
        setAuthState(RegistrationStates.Completed);
      }
      else {
        setError('Token missing', RegistrationStates.ShortOtcFailed);
      }
    } catch(error) {
      setError(error.message, RegistrationStates.ShortOtcFailed);
    }
  }

  function onTimeoutHandler() {
    errorToast(`Authentication timed out.`);
    console.error(`Authentication timed out.`);
    setShortOTC('');
  }
}
