import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';

type AuthComponentProps = {
  verifyTokens: ()=> Promise<any>,
  shortOTC: string,
  setShortOTC: (otc: string) => void,
}

export default function AuthView ({verifyTokens, shortOTC, setShortOTC}: AuthComponentProps) {

  const isCodeValid = !!shortOTC.match(/[0-9]{5,7}/);
  const [isRunning, setIsRunning] = useState(false);

  const isSubmitDisabled = !isCodeValid || isRunning;


  useEffect(() => {
    async function doVerify() {
      try {
        await verifyTokens();
      } finally {
        console.info('setIsRunning(false)');
        setIsRunning(false);
      }
    }

    if (isRunning) {
      doVerify();
    }

    return () => {};
  }, [isRunning, verifyTokens]);

  const buttonClasses = ['btn', 'btn-primary'];
  if (isRunning) {
    buttonClasses.push('is-loading');
  }
  console.info('button classes:', buttonClasses);



  return (
    <section className="tcap-connect__form-wrapper">
      <p>Please enter the One-time Authorisation Code Sent by Telegram.</p>
      <form onSubmit={onSubmit}>
        <input onChange={onOtcChange} autoFocus={true} minLength={5} maxLength={7}
          pattern="[0-9]{5,7}"/>
        { !isRunning &&
          <input disabled={isSubmitDisabled} className={buttonClasses.join(' ')} type="submit" style={{width: '100px'}}
            value="Verify token"/>
        }
        { isRunning &&
          <button disabled={true} className={buttonClasses.join(' ')} style={{width: '100px'}}>
            &nbsp;
          </button>
        }

      </form>
    </section>
  );

  function onOtcChange(event: ChangeEvent) {
    const otcInput = event.target as HTMLInputElement;
    setShortOTC(otcInput.value);
  }

  function onSubmit(event: FormEvent) {
    event.preventDefault();
    console.info('start submit');
    console.info('setIsRunning(true)');
    setIsRunning(true);
  }
}
